import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import imageIndex from '../ImageIndex';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { useState, useEffect } from 'react';

const StyledBox = styled('div')(({ theme }) => ({
  alignSelf: 'center',
  width: '100%',
  height: 400,
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(-8),
  borderRadius: theme.shape.borderRadius,
  outline: '1px solid',
  boxShadow: '0 0 12px 8px hsla(220, 25%, 80%, 0.2)',
  backgroundImage: `url(${'/static/images/templates/templates-images/hero-light.png'})`,
  outlineColor: 'hsla(220, 25%, 80%, 0.5)',
  backgroundSize: 'cover',
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(10),
    height: 700,
  },
  ...theme.applyStyles('dark', {
    boxShadow: '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
    backgroundImage: `url(${imageIndex.ENMICE202105})`,
    outlineColor: 'hsla(210, 100%, 80%, 0.1)',
  }),
}));

const items = [
  {
    description: 'El Encuentro Mexicano de Ingeniería en Cohetería Experimental (ENMICE), es el evento y competencia líder de vehículos lanzadores experimentales y de alta potencia en México organizada por la startup Explora. <strong style="color: hsl(210, 100%, 65%);">Convocamos y reunimos al talento de la nueva generación aeroespacial mexicana</strong> para fomentar, reconocer e incentivar el desarrollo de proyectos científico-tecnológicos aeroespaciales; específicamente de vehículos lanzadores y carga útil.\n\n<strong style="color: hsl(210, 100%, 65%);">Desde el 2021 impulsamos el desarrollo de cohetes y proyectos aeroespaciales en México</strong>.\n\nCon el apoyo de colaboradores, patrocinadores y equipos participantes <strong style="color: hsl(210, 100%, 65%);">somos parte del esfuerzo nacional para impulsar la participación y crecimiento del sector espacial</strong> y fortalecer el marco legal en México al reunir, reconocer y fomentar el desarrollo del talento espacial mexicano.\n\nA la fecha, hemos realizado tres ediciones nacionales:\n<strong style="color: hsl(210, 100%, 65%);">• Baja California</strong> (2021).\n<strong style="color: hsl(210, 100%, 65%);">• Jalisco</strong> (2022).\n<strong style="color: hsl(210, 100%, 65%);">• Chihuahua</strong> (2024).',
    imageDark: `url(${imageIndex.ENMICE202202})`,
    url: 'https://google.com',
  }
];

const items2 = [
  {
    description: '<strong style="color: hsl(210, 100%, 65%);">• Promover y fomentar</strong> interés por el desarrollo de ciencias y tecnologías espaciales.\n\n<strong style="color: hsl(210, 100%, 65%);">• Fortalecer</strong> la regularización de actividades espaciales como prioridad nacional.\n\n<strong style="color: hsl(210, 100%, 65%);">• Reunir y reconocer</strong> el talento mexicano que desarrolla proyectos aeroespaciales.\n\n<strong style="color: hsl(210, 100%, 65%);">• Coadyuvar</strong> en la independencia tecnológica para el acceso y la exploración del espacio exterior en beneficio de los mexicanos.',
    imageDark: `url(${imageIndex.ENMICE202203})`,
    url: 'https://google.com',
  }
];

const ImageCarousel = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const carouselImageStyle = {
    width: '100%', // Adjust width as needed
    height: '500px', // Adjust height as needed
    objectFit: 'cover', // This ensures the images cover the div without stretching
    position: 'relative',
  };

  const baseOverlayTextStyle = {
    position: 'absolute',
    top: '70%',
    backgroundColor: 'hsla(220, 25%, 10%, 0.9)',
    borderRadius: '12px',
    padding: '20px',
    fontSize: '40px',
    margin: 10
  };

  const mobileOverlayTextStyle = {
    fontSize: '10px', // Smaller font size for mobile
    padding: '20px', // Less padding for mobile
    top: '50%' // Adjust position for mobile
  };

  const overlayTextStyle = isMobile ? { ...baseOverlayTextStyle, ...mobileOverlayTextStyle } : baseOverlayTextStyle;

  const sliderTextStyle1 = {
    flexDirection: 'row',
    margin: 0,
    fontWeight: 600,
    lineHeight: 1.2,
    letterSpacing: '-0.5px',
    fontFamily: 'Inter, sans-serif',
    display: 'flex',
    WebkitBoxAlign: 'center',
    alignItems: 'center',
    fontSize: 'clamp(3rem, 10vw, 3.5rem)',
  };

  const sliderTextStyle2 = {
    margin: 0,
    fontWeight: 600,
    lineHeight: 1.2,
    letterSpacing: '-0.5px',
    fontFamily: 'Inter, sans-serif',
    color: 'hsl(210, 100%, 65%)',
    fontSize: 'inherit',
  };

  return (
    <Slider {...settings}>
      <div style={carouselImageStyle}>
        <img src={imageIndex.HomeCarousel1} alt="Evento" style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>
        {!isMobile && (
        <div style={overlayTextStyle}>
          <h1 style={sliderTextStyle1}>La competencia líder</h1>
          <h1 style={sliderTextStyle2}>de cohetería experimental en México</h1>
          {/*<button class="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary css-1wdir3v-MuiButtonBase-root-MuiButton-root">Convocatoria 2023</button>*/}
        </div>
        )}
      </div>
      <div style={carouselImageStyle}>
        <img src={imageIndex.HomeCarousel2} alt="Evento" style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>
        {!isMobile && (
        <div style={overlayTextStyle}>
        <h1 style={sliderTextStyle1}>La competencia líder</h1>
        <h1 style={sliderTextStyle2}>de cohetería experimental en México</h1>
          {/*<button class="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary css-1wdir3v-MuiButtonBase-root-MuiButton-root">Convocatoria 2023</button>*/}
        </div>
        )}
      </div>
      <div style={carouselImageStyle}>
        <img src={imageIndex.HomeCarousel7} alt="Evento" style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>
        {!isMobile && (
        <div style={overlayTextStyle}>
        <h1 style={sliderTextStyle1}>La competencia líder</h1>
        <h1 style={sliderTextStyle2}>de cohetería experimental en México</h1>
          {/*<button class="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary css-1wdir3v-MuiButtonBase-root-MuiButton-root">Convocatoria 2023</button>*/}
        </div>
        )}
      </div>
    </Slider>
  );
};

export default function LandingHero() {
  const [selectedItemIndex] = React.useState(0);
  const selectedFeature = items[selectedItemIndex];
  const selectedFeature2 = items2[selectedItemIndex];
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage:
          'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
        ...theme.applyStyles('dark', {
          backgroundImage:
            'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
        }),
      })}
    >
      <ImageCarousel />
      <Container id="features" sx={{ py: { xs: 4, sm: 6 } }}>
      <Grid container spacing={6}>
      <Grid item xs={12} md={6} sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}>
          <Card
            variant="outlined"
            sx={{
              height: '100%',
              width: '100%',
              display: { xs: 'none', sm: 'flex' },
              pointerEvents: 'none',
            }}
          >
            <Box
              sx={(theme) => ({
                width: '100%',
                minHeight: '700px',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                overflow: 'hidden',
                backgroundImage: 'var(--items-imageLight)',
                ...theme.applyStyles('dark', {
                  backgroundImage: 'var(--items-imageDark)',
                }),
              })}
              style={{
                '--items-imageLight': items[selectedItemIndex].imageLight,
                '--items-imageDark': items[selectedItemIndex].imageDark,
              }}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <div>
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
            <Typography variant="h2" sx={{ color: 'text.primary', marginRight: 1 }}>
              ¿Qué es
            </Typography>
            <Typography variant="h2" sx={{ color: 'primary.light' }}>
              ENMICE?
            </Typography>
          </Box>
          </div>
          <Card variant="outlined" sx={{ display: { xs: 'auto', sm: 'none' }, mt: 4 }}>
            <Box
              sx={(theme) => ({
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                minHeight: 280,
                backgroundImage: 'var(--items-imageLight)',
                ...theme.applyStyles('dark', {
                  backgroundImage: 'var(--items-imageDark)',
                }),
              })}
              style={{
                '--items-imageLight': items[selectedItemIndex].imageLight,
                '--items-imageDark': items[selectedItemIndex].imageDark,
              }}
            />
            <Box sx={{ px: 2, pb: 2, pt: 2 }}>
              <Typography variant="h6" sx={{ color: 'text.primary', mb: 1.5 }}
                    dangerouslySetInnerHTML={{ __html: selectedFeature.description.replace(/\n/g, '<br>') }}>
              </Typography>
            </Box>
          </Card>
          <Stack
            direction="column"
            spacing={2}
            useFlexGap
            sx={{
              justifyContent: 'center',
              alignItems: 'flex-start',
              width: '100%',
              display: { xs: 'none', sm: 'flex' },
            }}
          >
            {items.map(({ description }, index) => (
              <Card
                key={index}
                component={Button}
                sx={[
                  (theme) => ({
                    p: 3,
                    height: 'fit-content',
                    width: '100%',
                    background: 'none',
                    '&:hover': {
                      background: 'linear-gradient(to bottom right, hsla(210, 100%, 97%, 0.5) 25%, hsla(210, 100%, 90%, 0.3) 100%)',
                      borderColor: 'primary.light',
                      boxShadow: '0px 2px 8px hsla(0, 0%, 0%, 0.1)',
                      ...theme.applyStyles('dark', {
                        background: 'linear-gradient(to right bottom, hsla(210, 100%, 12%, 0.2) 25%, hsla(210, 100%, 16%, 0.2) 100%)',
                        borderColor: 'primary.dark',
                        boxShadow: '0px 1px 8px hsla(210, 100%, 25%, 0.5) ',
                      }),
                    },
                  }),
                  selectedItemIndex === index &&
                    ((theme) => ({
                      backgroundColor: 'action.selected',
                      borderColor: 'primary.light',
                      ...theme.applyStyles('dark', {
                        borderColor: 'primary.dark',
                      }),
                    })),
                ]}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    textAlign: 'left',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { md: 'center' },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={[
                      (theme) => ({
                        color: 'grey.400',
                        ...theme.applyStyles('dark', {
                          color: 'grey.600',
                        }),
                      }),
                      selectedItemIndex === index && {
                        color: 'primary.main',
                      },
                    ]}
                  >
                  </Box>
                  <div>
                    <Typography variant="h6" sx={{ color: 'text.primary', mb: 1.5, whiteSpace: 'pre-line'  }}
                    dangerouslySetInnerHTML={{ __html: description.replace(/\n/g, '<br>') }}>
                    </Typography>
                  </div>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
      </Grid>
    </Container>

    <Box sx={{ py: { xs: 4, sm: 6 }, display: 'flex', justifyContent: 'center' }}>
      <iframe
        width="800"
        height="450"
        src="https://www.youtube.com/embed/fYaF_x9SOT0?si=MbWrUnimjtbWDky-"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </Box>

    <Container id="features" sx={{ py: { xs: 4, sm: 8 } }}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={4}>
          <div>
            <Typography variant="h2" sx={{ color: 'text.primary', mb: { xs: 0, sm: 0 } }}>
            Nuestros
            </Typography>
            <Typography variant="h2" sx={{ color: 'primary.light', mb: { xs: 2, sm: 2 } }}>
            principales objetivos
            </Typography>
          </div>
          <Card variant="outlined" sx={{ display: { xs: 'auto', sm: 'none' }, mt: 4 }}>
            <Box
              sx={(theme) => ({
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                minHeight: 280,
                backgroundImage: 'var(--items2-imageLight)',
                ...theme.applyStyles('dark', {
                  backgroundImage: 'var(--items2-imageDark)',
                }),
              })}
              style={{
                '--items2-imageLight': items2[selectedItemIndex].imageLight,
                '--items2-imageDark': items2[selectedItemIndex].imageDark,
              }}
            />
            <Box sx={{ px: 2, pb: 2 }}>
              <Typography variant="h6" sx={{ color: 'text.primary', mb: 1.5, whiteSpace: 'pre-line'  }}
                    dangerouslySetInnerHTML={{ __html: selectedFeature2.description.replace(/\n/g, '<br>') }}>
              </Typography>
            </Box>
          </Card>
          <Stack
            direction="column"
            spacing={2}
            useFlexGap
            sx={{
              justifyContent: 'center',
              alignItems: 'flex-start',
              width: '100%',
              display: { xs: 'none', sm: 'flex' },
            }}
          >
            {items2.map(({ description }, index) => (
              <Card
                key={index}
                component={Button}
                sx={[
                  (theme) => ({
                    p: 3,
                    height: 'fit-content',
                    width: '100%',
                    background: 'none',
                    '&:hover': {
                      background: 'linear-gradient(to bottom right, hsla(210, 100%, 97%, 0.5) 25%, hsla(210, 100%, 90%, 0.3) 100%)',
                      borderColor: 'primary.light',
                      boxShadow: '0px 2px 8px hsla(0, 0%, 0%, 0.1)',
                      ...theme.applyStyles('dark', {
                        background: 'linear-gradient(to right bottom, hsla(210, 100%, 12%, 0.2) 25%, hsla(210, 100%, 16%, 0.2) 100%)',
                        borderColor: 'primary.dark',
                        boxShadow: '0px 1px 8px hsla(210, 100%, 25%, 0.5) ',
                      }),
                    },
                  }),
                  selectedItemIndex === index &&
                    ((theme) => ({
                      backgroundColor: 'action.selected',
                      borderColor: 'primary.light',
                      ...theme.applyStyles('dark', {
                        borderColor: 'primary.dark',
                      }),
                    })),
                ]}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    textAlign: 'left',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { md: 'center' },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={[
                      (theme) => ({
                        color: 'grey.400',
                        ...theme.applyStyles('dark', {
                          color: 'grey.600',
                        }),
                      }),
                      selectedItemIndex === index && {
                        color: 'primary.main',
                      },
                    ]}
                  >
                  </Box>
                  <div>
                    <Typography variant="h6" sx={{ color: 'text.primary', mb: 1.5, whiteSpace: 'pre-line'  }}
                    dangerouslySetInnerHTML={{ __html: description.replace(/\n/g, '<br>') }}>
                    </Typography>
                  </div>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
        <Grid item xs={12} md={8} sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}>
          <Card
            variant="outlined"
            sx={{
              height: '100%',
              width: '100%',
              display: { xs: 'none', sm: 'flex' },
              pointerEvents: 'none',
            }}
          >
            <Box
              sx={(theme) => ({
                width: '100%',
                height: '100%',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                overflow: 'hidden',
                backgroundImage: 'var(--items2-imageLight)',
                ...theme.applyStyles('dark', {
                  backgroundImage: 'var(--items2-imageDark)',
                }),
              })}
              style={{
                '--items2-imageLight': items2[selectedItemIndex].imageLight,
                '--items2-imageDark': items2[selectedItemIndex].imageDark,
              }}
            />
          </Card>
        </Grid>
      </Grid>
      <StyledBox id="image" />
    </Container>
    </Box>
  );
}
