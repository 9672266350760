import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';

import imageIndex from '../ImageIndex';

const itemsExecutiveComitee = [
  {
    image: imageIndex.AboutUsRamonCordovaImage,
    icon: <AutoFixHighRoundedIcon />,
    title: 'Ramon Córdova',
    description:
      'Director General',
  },
  {
    image: imageIndex.AboutUsFelipeAvilaImage,
    icon: <SettingsSuggestRoundedIcon />,
    title: 'Felipe Ávila',
    description:
      'Director de Operaciones y Estrategia',
  },
  {
    image: imageIndex.AboutUsWalterAhrens,
    icon: <ConstructionRoundedIcon />,
    title: 'Walter Ahrens',
    description:
      'Director de Operaciones y Lanzamiento',
  },
  {
    image: imageIndex.AboutUsArielGomezImage,
    icon: <ThumbUpAltRoundedIcon />,
    title: 'Ariel Gómez',
    description:
      'Director de Información y Tecnología',
  },
  {
    image: imageIndex.AboutUsPaulinaAguayoImage,
    icon: <AutoFixHighRoundedIcon />,
    title: 'Paulina Aguayo',
    description:
      'Coordinadora de Operaciones de Lanzamiento',
  },
  {
    image: imageIndex.AboutUsXimenaVelascoImage,
    icon: <AutoFixHighRoundedIcon />,
    title: 'Ximena Velasco',
    description:
      'Coordinadora de Alianzas y Patrocinios',
  },
  {
    image: imageIndex.AboutUsMalcomPinedaImage,
    icon: <AutoFixHighRoundedIcon />,
    title: 'Malcom Pineda',
    description:
      'Coordinador ENMICE Campus Chief',
  },
  {
    image: imageIndex.AboutUsPaulinaCabreraImage,
    icon: <AutoFixHighRoundedIcon />,
    title: 'Paulina Cabrera',
    description:
      'Coordinadora del Área de Diseño y Artista Digital Creativa',
  },
  {
    image: imageIndex.AboutUsRossyDelgadoImage,
    icon: <AutoFixHighRoundedIcon />,
    title: 'Rossy Delgado',
    description:
      'Directora de Academia ENMICE',
  },
  {
    image: imageIndex.AboutUsPatyLomeliImage,
    icon: <AutoFixHighRoundedIcon />,
    title: 'Paty Lomelí',
    description:
      'Artista Digital Creativa',
  },
  {
    image: imageIndex.AboutUsGodricAcevesImage,
    icon: <AutoFixHighRoundedIcon />,
    title: 'Godric Aceves',
    description:
      'Diseñador y Desarrollador Web',
  },
  {
    image: imageIndex.AboutUsAngelVazquezImage,
    icon: <ConstructionRoundedIcon />,
    title: 'Ángel Vázquez',
    description:
      'Diseñador y Desarrollador Web',
  },
  {
    image: imageIndex.AboutUsMaiteCUImage,
    icon: <AutoFixHighRoundedIcon />,
    title: 'Maité Cu',
    description:
      'Artista Digital Creativa',
  },
  {
    image: imageIndex.AboutUsAnaelUlailaImage,
    icon: <AutoFixHighRoundedIcon />,
    title: 'Anael Ulaila',
    description:
      'Community Manager',
  },
  {
    image: imageIndex.AboutUsBruceImage,
    icon: <AutoFixHighRoundedIcon />,
    title: 'Bruno Ramírez',
    description:
      'Creador de Contenido',
  },
  {
    image: imageIndex.AboutUsJofielGarciaImage,
    icon: <AutoFixHighRoundedIcon />,
    title: 'Jofiel Hernández',
    description:
      'Ejecutivo de Alianzas y Patrocinio',
  },
  {
    image: imageIndex.AboutUsAntonioRodriguezImage,
    icon: <AutoFixHighRoundedIcon />,
    title: 'Antonio Rodríguez',
    description:
      'Ejecutivo de Alianzas y Patrocinio',
  },
  {
    image: imageIndex.AboutUsBraulioAlvarezImage,
    icon: <AutoFixHighRoundedIcon />,
    title: 'Braulio Álvarez',
    description:
      'Analista de Pruebas y Operaciones de Lanzamiento',
  },
  {
    image: imageIndex.AboutUsOmarSanchezImage,
    icon: <AutoFixHighRoundedIcon />,
    title: 'Omar Sánchez',
    description:
      'Analista de Pruebas y Operaciones de Lanzamiento',
  },
  {
    image: imageIndex.AboutUsRodrigoMorenoImage,
    icon: <AutoFixHighRoundedIcon />,
    title: 'Rodrigo Moreno',
    description:
      'Líder de Redacción de Artículos Científicos',
  },
];

const itemsMentions = [
  {
    image: imageIndex.AboutUsGabrielBasilioImage,
    icon: <SettingsSuggestRoundedIcon />,
    title: 'Gabriel Basilio',
    description:
      'Campus Chief CDMX',
  },
  {
    image: imageIndex.AboutUsJoseAndresImage,
    icon: <ConstructionRoundedIcon />,
    title: 'José Andrés',
    description:
      'Campus Chief CDMX',
  },
  {
    image: imageIndex.AboutUsKarlaDiazImage,
    icon: <ThumbUpAltRoundedIcon />,
    title: 'Karla Díaz',
    description:
      'Campus Chief Jalisco',
  },
  {
    image: imageIndex.AboutUsLeonelImage,
    icon: <AutoFixHighRoundedIcon />,
    title: 'Leonel González',
    description:
      'Campus Chief Jalisco',
  },
  {
    image: imageIndex.AboutUsMalcomPinedaImage,
    icon: <AutoFixHighRoundedIcon />,
    title: 'Malcom Pineda',
    description:
      'Coordinador Campus Chief',
  },
  {
    image: imageIndex.AboutUsMariaFernandaImage,
    icon: <AutoFixHighRoundedIcon />,
    title: 'María Fernanda',
    description:
      'Campus Chief Baja California',
  },
  {
    image: imageIndex.AboutUsMtroJesusImage,
    icon: <AutoFixHighRoundedIcon />,
    title: 'Jesús Zazueta',
    description:
      'Campus Chief Sonora',
  },
  {
    image: imageIndex.AboutUsValeriaCurielImage,
    icon: <AutoFixHighRoundedIcon />,
    title: 'Valeria Curiel',
    description:
      'Campus Chief CDMX',
  }
];

export default function AboutUsHighlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Grid container spacing={2.5} justifyContent="center" alignItems="center">
          {itemsExecutiveComitee.map((item, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Stack
                direction="column"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  color: 'inherit',
                  p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: 'hsla(220, 25%, 25%, .3)',
                  background: 'transparent',
                  backgroundColor: 'grey.900',
                  boxShadow: 'none',
                }}
              >
                <img src={item.image} alt={item.title} style={{ width: '100%', height: 'auto', objectFit: 'cover', borderRadius: '8px' }} />
                <div style={{ textAlign: 'center' }}>
                  <Typography gutterBottom sx={{ fontWeight: 'medium' }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4" sx={{ marginBottom: 2, marginTop: 10 }}>
            Campus Chief
          </Typography>
          {/*<Typography variant="body1" sx={{ color: 'grey.400' }}>
            Zy
          </Typography>*/}
        </Box>
        <Grid container spacing={2.5} justifyContent="center" alignItems="center">
          {itemsMentions.map((item, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Stack
                direction="column"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  color: 'inherit',
                  p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: 'hsla(220, 25%, 25%, .3)',
                  background: 'transparent',
                  backgroundColor: 'grey.900',
                  boxShadow: 'none',
                }}
              >
                <img src={item.image} alt={item.title} style={{ width: '100%', height: 'auto', objectFit: 'cover', borderRadius: '8px' }} />
                <div style={{ textAlign: 'center' }}>
                  <Typography gutterBottom sx={{ fontWeight: 'medium' }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
